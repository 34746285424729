<template>
  <div>
    <v-col cols="12" class="title-overwrite">
      <v-icon class="icon-overwrite">build</v-icon>
      {{ frequencyType ? 'Frequency Target' : 'Frequency Overwrite'  }}
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-select
          :items="overwriteItemByDsp"
          item-text="text"
          item-value="id"
          v-model="selectedOverwrite"
          :rules="[rules.required]"
          tab="tab1"
        >
        </v-select>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="showSelectConstraint">
      <v-row>
        <v-col class="mx-2" v-show="showSelectConstraint">
          <v-text-field
            v-model="exposures"
            :rules="[rules.required, rules.numeric]"
            tab="tab1"
            :error-messages="errorMessagesExposures()"
          >

          </v-text-field>
        </v-col>

        <v-col class="grow pt-4 mx-2 justify-center ma-auto" v-show="showSelectConstraint">
          <span class="font-italic imp-per-span">imp per</span>
        </v-col>

        <v-col class="mx-2" v-if="allowSetAmount && showSelectConstraint">
          <v-text-field
            v-model="amount"
            :rules="selectedPeriodIsLifetime ? [] : [rules.required, rules.numeric]"
            v-show="!selectedPeriodIsLifetime"
            :error-messages="errorMessagesAmount()"
            tab="tab1"
          >

          </v-text-field>
        </v-col>

        <v-col class="mx-2" v-show="showSelectConstraint">
          <v-select
            :items="periodItemsPerDsp"
            v-model="selectedPeriod"
            item-text="text"
            item-value="period"
            :rules="[rules.required]"
            :error-messages="errorMessagesPeriod()"
            tab="tab1"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { generalMixin } from '../../../../mixins/generalMixin'
import { rulesMixin } from '../../../../mixins/rulesMixin'

const TRUE_NO_CONSTRAINTS = 'true_no_constraints'
const TRUE_WITH_CONSTRAINTS = 'true_with_constraints'
const FALSE_NO_CONSTRAINTS = 'false_no_constraints'

const HOURS = 'Hours'
const MINUTES = 'Minutes'
const DAYS = 'Days'
const WEEKS = 'Weeks'
const MONTHS = 'Months'
const LIFETIME = 'Lifetime'

export default {
  name: 'OverwriteFrequencyInput',
  props: {
    maxFrequencyProps: {
      type: Object,
      default: () => {
        return {
          exposures: '',
          period: '',
          amount: ''
        }
      }
    },
    overwriteFrequencyProps: {
      type: Boolean
    },
    frequencyType: {
      type: String
    }
  },
  data: function () {
    return {
      overwriteItems: [
        { text: 'Overwrite with no "Max" constraints', id: TRUE_NO_CONSTRAINTS, value: { overwriteFrequency: true, withConstraints: false } },
        { text: 'Overwrite but with "Max" constraints', id: TRUE_WITH_CONSTRAINTS, value: { overwriteFrequency: true, withConstraints: true } },
        { text: 'Not Allowed to overwrite frequency', id: FALSE_NO_CONSTRAINTS, value: { overwriteFrequency: false, withConstraints: false } }
      ],
      periodItems: {
        [this.$DBM]: [
          { text: 'minute(s)', period: MINUTES },
          { text: 'hour(s)', period: HOURS },
          { text: 'day(s)', period: DAYS },
          { text: 'week(s)', period: WEEKS },
          { text: 'month(s)', period: MONTHS },
          { text: 'lifetime', period: LIFETIME }
        ],
        [this.$YOUTUBE]: [
          { text: 'minute(s)', period: MINUTES },
          { text: 'hour(s)', period: HOURS },
          { text: 'day(s)', period: DAYS },
          { text: 'week(s)', period: WEEKS },
          { text: 'month(s)', period: MONTHS },
          { text: 'lifetime', period: LIFETIME }
        ],
        [this.$MEDIAMATH]: [
          { text: 'hour', period: `1_${HOURS}` },
          { text: 'day', period: `1_${DAYS}` },
          { text: '7 days', period: `7_${DAYS}` },
          { text: '30 days', period: `30_${DAYS}` }
        ],
        [this.$APPNEXUS]: [
          { text: 'hour', period: `1_${HOURS}` },
          { text: 'day', period: `1_${DAYS}` },
          { text: 'week', period: `1_${WEEKS}` },
          { text: 'month', period: `1_${MONTHS}` },
          { text: 'lifetime', period: `1_${LIFETIME}` }
        ],
        [this.$BEESWAX]: [
          { text: 'hour(s)', period: HOURS },
          { text: 'day(s)', period: DAYS },
          { text: 'week(s)', period: WEEKS },
          { text: 'minute(s)', period: MINUTES }
        ],
        [this.$THETRADEDESK]: [
          { text: 'minute(s)', period: MINUTES },
          { text: 'hour(s)', period: HOURS },
          { text: 'day(s)', period: DAYS },
          { text: 'week(s)', period: WEEKS },
          { text: 'month(s)', period: MONTHS },
          { text: 'lifetime', period: LIFETIME }
        ],
        [this.$META]: [
          { text: 'day(s)', period: DAYS }
        ]
      },
      /**
       * @type {Period|string}
       */
      selectedPeriod: '',
      exposures: 1,
      amount: 1,
      overwriteFrequency: false,
      selectedOverwrite: FALSE_NO_CONSTRAINTS,
      withConstraints: false
    }
  },
  mixins: [generalMixin, rulesMixin],
  mounted () {
  },
  methods: {
    checkOverwriteFrequencyValue (overwritePropsValue) {
      if (overwritePropsValue && this.withConstraints) {
        this.selectedOverwrite = TRUE_WITH_CONSTRAINTS
      } else if (overwritePropsValue && !this.withConstraints) {
        this.selectedOverwrite = TRUE_NO_CONSTRAINTS
      } else if (!overwritePropsValue && !this.withConstraints) {
        this.selectedOverwrite = FALSE_NO_CONSTRAINTS
      }
    },
    checkMaxFrequencyValue (maxFrequencyValue) {
      if (!this.maxFrequencyIsSet(maxFrequencyValue)) {
        this.withConstraints = false
        if (this.allowSetAmount) {
          this.amount = 1
        }
        this.exposures = 1
        this.selectedPeriod = this.allowSetAmount ? HOURS : `1_${HOURS}`
      } else {
        this.withConstraints = true
        this.exposures = this.maxFrequencyProps.exposures

        if (this.allowSetAmount) {
          this.amount = this.maxFrequencyProps.amount
          this.selectedPeriod = this.maxFrequencyProps.period
        } else {
          this.amount = null
          this.selectedPeriod = `${this.maxFrequencyProps.amount}_${this.maxFrequencyProps.period}`
        }
      }
    },
    maxFrequencyIsSet (maxFrequencyValue) {
      return !this.$isNullOrUndefined(maxFrequencyValue) && !this.$commonUtils.objectIsEmpty(maxFrequencyValue) && !this.allValueAreNull(maxFrequencyValue)
    },
    allValueAreNull (maxFrequencyValue) {
      return !maxFrequencyValue.period && !maxFrequencyValue.exposures && !maxFrequencyValue.amount
    },
    errorMessagesExposures () {
      let errors = []
      if (this.dsp === this.$APPNEXUS) {
        let res = this.betweenRule('exposures', 0, 255)
        if (res) {
          errors.push(res)
        }
      } else if ([this.$DBM, this.$YOUTUBE].includes(this.dsp)) {
        let res = this.betweenRule('exposures', 0, 2_000_000_000)
        if (res) {
          errors.push(res)
        }
      }
      return errors
    },
    errorMessagesAmount () {
      let errors = []
      let min = null
      let max = null
      if ([this.$DBM, this.$YOUTUBE].includes(this.dsp)) {
        const period = this.maxFrequencyProps.period

        if (period === MINUTES) {
          min = 1
          max = 59
        } else if (period === HOURS) {
          min = 1
          max = 23
        } else if (period === DAYS) {
          min = 1
          max = 6
        } else if (period === WEEKS) {
          min = 1
          max = 4
        } else if (period === MONTHS) {
          min = 1
          max = 2
        }
      }

      if (min && max) {
        let res = this.betweenRule('amount', min, max)
        if (res) {
          errors.push(res)
        }
      }
      return errors
    },
    errorMessagesPeriod () {
      const errors = []
      const allowedPeriod = this.periodItemsPerDsp.map(item => item.period)
      if (!this.selectedPeriod || !allowedPeriod.includes(this.selectedPeriod)) {
        errors.push('Period should be set!')
      }
      return errors
    }
  },
  computed: {
    allowSetAmount () {
      return [this.$DBM, this.$BEESWAX, this.$THETRADEDESK, this.$YOUTUBE, this.$META].indexOf(this.dsp) !== -1
    },
    periodItemsPerDsp () {
      return this.periodItems[this.dsp]
    },
    /**
     * @return {MaxFrequency|{}}
     */
    maxFrequency () {
      if (this.withConstraints === false) {
        return {}
      }

      if (!this.allowSetAmount) {
        let selectedPeriod = this.selectedPeriod.split('_')
        let period = selectedPeriod[1]
        let amount = selectedPeriod[0]
        return {
          exposures: isNaN(this.exposures) ? '' : Number(this.exposures),
          period: period,
          amount: Number(amount)
        }
      }

      let amount = isNaN(this.amount) ? '' : Number(this.amount)

      if (this.selectedPeriodIsLifetime) {
        amount = null
      }
      return {
        exposures: isNaN(this.exposures) ? '' : Number(this.exposures),
        period: this.selectedPeriod,
        amount: amount
      }
    },
    showSelectConstraint () {
      return this.withConstraints
    },
    selectedPeriodIsLifetime () {
      return this.selectedPeriod === LIFETIME
    },
    overwriteItemByDsp () {
      // in case we need to filter on some values for some dsp
      return this.overwriteItems
    }
  },
  watch: {
    maxFrequency: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.$emit('updateMaxFrequency', value)
      }
    },
    maxFrequencyProps: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.checkMaxFrequencyValue(value)
        this.checkOverwriteFrequencyValue(this.overwriteFrequencyProps)
      }
    },
    overwriteFrequencyProps: {
      immediate: true,
      handler: function (value) {
        this.checkOverwriteFrequencyValue(value)
      }
    },
    selectedOverwrite: {
      immediate: true,
      deep: true,
      handler: function (value) {
        let rightObject = this.overwriteItems.filter((item) => {
          return item.id === value
        })[0].value
        this.withConstraints = rightObject.withConstraints
        this.$emit('updateOverwriteFrequency', rightObject.overwriteFrequency)
      }
    }
  }
}
</script>

<style scoped>
.imp-per-span {
  font-size: 10px;
}

.title-overwrite {
  font-weight: bold;
}

.icon-overwrite {
  font-size: 16px;
  padding-right: 1em;
}
</style>
